// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "d3"
import "chartkick/chart.js"
//require("bootstrap/dist/js/bootstrap")
//import 'bootstrap';
//import 'bootstrap/dist/js/bootstrap'
//import 'bootstrap/dist/css/bootstrap'


require("jquery")
require("jquery.easing/jquery.easing")
////import "chartkick/chart.js" //this is the node_module. shoudl it be 'chartkick' to get the package.json?
//require("chart.js")
//require("chartkick/chart.js")
////global.Chart  = require("chartkick/chart.js")
require('datatables.net-bs4')

require("src/vendor")
require("src/sb-admin")
require("src/easing-moves")

require("@nathanvda/cocoon")

global.toastr = require("toastr")
//import "../stylesheets/application"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

var $  = require( 'jquery' );
var dt = require('datatables.net-bs4');
window.$ = $
